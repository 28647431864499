// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
// blues
$blue-lightest:#3FE9FF;
$blue-lighter:#00E2FF;
$blue-lighter-two: #3FB8FF;
$blue-light:#00A0FF;
$blue-light-two: #008CDF;
$blue:#1C5D88;
$blue-dark:#204761;
// yellows
$yellow:#FFDF00;
$yellow-dark:#F8C823;
$yellow-dark-two:#DFC400;
$yellow-darker:#CCB300;
// oranges
$orange-light: #FFB53F;
$orange:#FF9C00;
$orange-dark:#CC7C00;
// purples
$purple:#C31162;
$purple-dark:#9C0E4D;
$purple-dark-two:#AA0E56;
// reds
$red:#FF3A44;
$red-dark:#CC2D36;
$red-dark-two: #DF333B;
// greens
$green:#04AB38;
$green-dark:#1C8863;
// mints
$mint-lightest:#3FF497;
$mint-light:#00EF75;
$mint:#31A070;
$mint-dark:#64B895;
// monotones
$grey: #FAFAF3;
$gray-dark:#A6A6A6;
$white: #fff;
$black: #000;

// theme-colors
$primary: $blue;
$secondary: $yellow-dark;

// body
$body-bg: $grey;
$body-color: $black;

// typography
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Hind:wght@300;400;500;600;700&display=swap');
$font-family-primary:  "Hind", sans-serif;
$font-family-secondary: "Acme", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$font-size-base: 1.125rem; // 18px

$h1-font-size: $font-size-base * 2.6667; // 48px
$h2-font-size: $font-size-base * 2; // 36px
$h3-font-size: $font-size-base * 1.6667; // 30px
$h4-font-size: $font-size-base * 1.3333; // 24px
$h5-font-size: $font-size-base * 1.1111; // 20px
$h6-font-size: $font-size-base; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 500;
$headings-color: $black;

// contextual
$light: $yellow-dark;
$dark: $primary;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
