// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home intro
.home-intro-section {
	margin: 6vh auto;
	.container {
		.container-holder {
			max-width: 70%;
			margin: auto;
			@include media-breakpoint-down(lg) {
				max-width: 95%;
			}
			.column {
				text-align: center;
				div.title {
					.title {
						color: $blue;
					}
					.subtitle {
						@extend .special-subtitle;
					}
				}
				.description {
					margin: 3vh auto 0 auto;
					.btn {
						margin-top: 1rem;
					}
				}
			}
		}
	}
}
// usps home

// splash animation
@keyframes splash {
	from {
		opacity: 0;
		scale: 2;
	}
	to {
		opacity: 1;
		scale: 1;
	}
}

.home-usp-section {
	margin: 6vh auto;
	min-height: calc(12vh + 300px);
	.vocabulary-term-list {
		@extend .splash-list;
	}
}

// home acco section
.home-accommodation-section {
	margin: 6vh auto;
	@include media-breakpoint-down(lg) {
		background: $black;
		margin: 6vh 1rem;
		border-radius: 15px;
	}
	.container-fluid {
		.container-holder {
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				overflow: hidden;
			}
			.one {
				border-radius: 0 30px 30px 0;
				max-width: calc(60% - 0.5rem) !important;
				flex-basis: calc(60% - 0.5rem) !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
					margin: 0;
					padding: 0;
					.owl-carousel {
						min-height: auto;
					}
				}
				.accommodations {
					.owl-item {
						aspect-ratio: 2/1.25;
						border-radius: 0 30px 30px 0;
						@include media-breakpoint-down(lg) {
							aspect-ratio: none;
							border-radius: 15px 15px 0 0;
							max-height: 101%;
							overflow: hidden;
							height: 300px;
						}
						.item {
							border-radius: 0 30px 30px 0;
							padding: 0;
							@include media-breakpoint-down(lg) {
								border-radius: 15px 15px 0 0;
								max-height: 100%;
							}
						}
						.owl-caption-holder {
							display: flex;
							height: 100%;
							align-items: flex-end;
							z-index: 1;
							@include media-breakpoint-down(lg) {
								max-height: 100%;
							}
							&::before {
								content: "";
								position: absolute;
								bottom: 0;
								left: 0;
								height: 100%;
								width: 100%;
								border-radius: 0 30px 30px 0;
								background: linear-gradient(
									to top,
									rgba(#000, 0.45),
									rgba(#000, 0) 35%
								);
								z-index: -1;
								@include media-breakpoint-down(lg) {
									display: none;
								}
							}
							.owl-caption {
								display: flex;
								flex-flow: column;
								align-items: flex-end;
								margin-bottom: 1.5rem;
								margin-right: 1.5rem;
								z-index: 3;
								.owl-title {
									font-size: $h5-font-size;
									color: $yellow-dark;
									font-weight: bold;
									font-family: $font-family-primary;
								}
								.owl-subtitle {
									font-family: $font-family-secondary;
									font-size: $h1-font-size;
								}
								@include media-breakpoint-down(lg) {
									align-items: flex-start;
									.owl-subtitle {
										font-size: 36px;
										z-index: 900;
										position: relative;
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
			.two {
				border-radius: 30px 0 0 30px;
				background: $secondary;
				max-width: calc(40% - 0.5rem) !important;
				flex-basis: calc(40% - 0.5rem) !important;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				margin-left: auto;
				padding: 1.5rem 0;
				.wysiwyg {
					max-width: 90%;
					p {
						margin-bottom: 3vh;
					}
				}
				@include media-breakpoint-down(lg) {
					max-width: 90% !important;
					flex-basis: 90% !important;
					border-radius: 15px;
					margin: 0 auto 3vh auto;
					padding: 3vh 15px 0 15px;
					position: relative;
					&::before {
						content: "";
						border-radius: 30px 30px 0 0;
						height: 24vh;
						position: absolute;
						left: -1.5rem;
						top: -24vh;
						width: calc(100% + 3rem);
						z-index: 1;
						background: linear-gradient(
							to top,
							rgba(#000, 1),
							rgba(#000, 0) 45%
						);
						pointer-events: none;
					}
					.wysiwyg {
						max-width: 100%;
					}
				}
			}
		}
	}
}

// highlights section
.home-highlights-section {
	margin: 6vh auto;
	min-height: 560px;
	.container.one {
		margin-top: 16vh !important;
		@include media-breakpoint-down(md) {
			margin-top: 3vh !important;
		}
		.title * {
			color: $blue;
			text-align: center;
			@include media-breakpoint-down(md) {
				font-size: 36px;
				max-width: 80%;
				margin: 1rem auto;
			}
		}
	}
	.container.two {
		position: relative;
		max-width: 100%;
		padding-right: 0;
		.container-holder {
			margin-right: 0;
			padding-right: 0;
			padding-top: 1rem;
			padding-bottom: 12vh;
			overflow: hidden;
			.column {
				padding-right: 0;
				.owl-stage-outer {
					overflow: visible;
				}
				.owl-item {
					&:nth-child(2n) {
						transform: translateY(6vh);
					}
					&:nth-child(4n) {
						transform: translateY(12vh);
					}
					.item {
						.card {
							position: relative;
							min-width: 100% !important;
							border-radius: 20px;
							.card-image {
								position: relative;
								&::after {
									content: "";
									position: absolute;
									width: 100%;
									height: 100%;
									bottom: 0;
									left: 0;
									background: linear-gradient(
										to top,
										rgba(#000, 0.45),
										rgba(#000, 0) 35%
									);
								}
							}
							.card-body {
								position: absolute;
								bottom: 0;
								left: 0;

								.card-title {
									color: $yellow-dark;
									font-family: $font-family-primary;
									font-size: $h5-font-size;
									font-weight: bold;
								}
								.card-subtitle {
									color: $white;
									font-family: $font-family-secondary;
									font-size: $h2-font-size;
								}
								.card-description {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// animation section
.home-animation-section {
	background: $yellow-dark;
	padding: 12vh 0;
	margin: 3vh 2rem;
	border-radius: 30px;
	@include media-breakpoint-down(lg) {
		padding: 6vh 0;
	}
	.container.intro {
		.container-holder {
			max-width: max-content;
			margin: auto;
			.column {
				text-align: center;
				.subtitle {
					@extend .special-subtitle;
				}
			}
		}
	}
	.container.grid-container {
		margin-top: 4vh;
		img {
			border-radius: 20px;
		}
		.one,
		.two {
			padding-right: 0 !important;
			padding-left: 1.5rem !important;
			@include media-breakpoint-down(lg) {
				padding: 15px !important;
				margin-bottom: 0;
			}
		}
		.one {
			display: flex;
			flex-flow: column;
			gap: 1.5rem;
			align-items: center;

			.resource-image {
				&:nth-child(2) {
					img {
						max-width: 250px;
					}
				}
			}
		}
		.two {
			display: flex;
			flex-flow: column;
			gap: 2.5rem;
			@include media-breakpoint-down(lg) {
				order: -1;
			}
		}
	}
}

// home usp section two
.home-usp-section-two {
	@include media-breakpoint-down(md) {
		display: none;
		// dit zat in het ontwerp..
	}
	margin: 9vh auto;
	.container.intro {
		.container-holder {
			max-width: max-content;
			margin: auto;

			.column {
				text-align: center;

				.subtitle {
					@extend .special-subtitle;
				}
			}
		}
	}
	.container.list-container {
		margin-top: 6vh;
		.container-holder {
			max-width: 90%;
			margin: auto;

			.one,
			.two {
				padding: 0;
				padding-left: 0 !important;
				li {
					color: $blue;
				}
			}
			.one {
				align-items: flex-end;
				padding-right: 0 !important;
			}
			.two {
				align-items: center;
				ul {
					margin-left: 2rem;
				}
			}
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
	}
}

// family section
.home-family-section {
	.container,
	.container-two-columns,
	.container-fluid {
		.container-holder {
			@include media-breakpoint-down(md) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
			> .column {
				&.one {
					@extend .pr-5;

					flex-basis: 60% !important;
					max-width: 60% !important;
					overflow: hidden;
					border-radius: 20px !important;

					.video {
						height: 40vh;
						border-radius: 20px !important;
						iframe {
							height: 100%;
							scale: 1.1;
						}
					}
				}

				&.two {
					@extend .pl-0;

					flex-basis: 40% !important;
					max-width: 40% !important;
					overflow: hidden;
					border-radius: 0 20px 20px 0;

					.resource-image {
						height: 40vh;

						.image-holder {
							height: 100%;
							position: relative;

							.image-holder-image {
								position: relative;
								height: 100%;
								overflow: hidden;
								border-radius: 0 20px 20px 0;
								img {
									object-fit: cover;
									height: 100%;
									border-radius: 0 20px 20px 0;
									position: relative;
								}
								&::after {
									content: "";
									position: absolute;
									left: 0;
									top: 0;
									width: 100%;
									height: 100%;
									background: linear-gradient(
										to top,
										rgba(black, 0.7),
										rgba(white, 0) 80%
									);
									border-radius: 0 20px 20px 0;
								}
							}
							.image-holder-caption {
								background: none;
								position: absolute;
								bottom: 0;
								right: 15px;
								text-align: right;
								.image-holder-caption-title {
									color: $yellow-dark;
									font-size: 20px;
									font-family: $font-family-primary;
									font-weight: 600;
								}
								.image-holder-caption-subtitle {
									color: $white !important;
								}
								.image-holder-caption-description {
									color: $white;
									font-size: 14px;
								}
							}
						}
					}
				}
				@include media-breakpoint-down(md) {
					&.one,
					&.two {
						flex-basis: 100% !important;
						max-width: 100% !important;
					}

					&.one {
						margin: 0;
						padding: 0 !important;
						align-items: flex-end;
						border-radius: 20px 20px 0 0 !important;

						.video {
							position: relative;
							width: unset !important;
							height: unset !important;

							iframe {
								width: 100vw;
								height: unset !important;
								aspect-ratio: 16/9;
							}
						}
					}

					&.two {
						padding: 0;
						border-radius: 0 0 20px 20px !important;

						.resource-image {
							min-height: 40vh;

							.image-holder-image {
								border-radius: 0 !important;

								img {
									border-radius: 0 !important;
								}

								&::after {
									border-radius: 0 !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

// last-minute section
.home-last-minute-section {
	margin: 9vh auto;
	.container.intro {
		.container-holder {
			max-width: max-content;
			margin: auto;
			margin-bottom: 6vh;

			.column {
				text-align: center;

				.subtitle {
					@extend .special-subtitle;
				}
				.wysiwyg {
					max-width: 700px;
					margin: 3vh auto;
					text-align: center;
				}
			}
		}
	}
	.card-container {
		padding: 0 0 2.5rem 0;
		overflow: hidden;
		max-width: 80%;
		margin-left: auto;
		margin-right: 0;
		@include media-breakpoint-down(lg) {
			max-width: 95%;
			margin: auto 1px auto auto;
		}
		.owl-stage-outer {
			overflow: visible;
			.owl-item {
				&:nth-child(2n) {
					.card {
						transform: translateY(2rem);
					}
				}
			}
		}
		.card {
			@extend .default-card;

			.card-image,
			.card-image img {
				aspect-ratio: 1/1.114;
				object-fit: cover;
			}
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		display: flex;
		flex-flow: column;
		.title {
			order: -1;
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
		.subtitle > * {
			@extend .special-subtitle;
			max-width: 750px;
			margin: auto;
		}
		.wysiwyg {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
				font-weight: 600;
				text-transform: uppercase;
				color: $primary;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
