// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: column;
	gap: 1rem;
	li {
		position: relative;
		font-size: 18px;
		font-weight: bold;
		display: flex;
		align-items: center;
		gap: 0.45rem;

		&::before {
			content: "\f336";
			font-family: $font-awesome;
			color: $primary;
			font-weight: 600;
			font-size: 22px;
		}
	}
}

// usp splash list
.splash-list {
	width: 100%;
	justify-content: center;
	align-items: center;

	li {
		display: block;
		width: 176px;
		max-width: 176px;
		aspect-ratio: 1/1;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $h3-font-size;
		color: $white;
		text-align: center;
		z-index: 4;
		position: relative;
		font-family: $font-family-secondary;
		transition: 0.35s ease;
		@include media-breakpoint-down(lg) {
			width: 110px;
			max-width: 110px;
			font-size: 16px;
		}
		&:hover {
			scale: 1.1;
			transition: 0.35s ease;
			z-index: 99 !important;
		}

		&.enter {
			animation: splash 1s ease 1;
		}

		&::before {
			content: url("/images/splash-0.svg");
			z-index: -1;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -45%) scale(1.25);
			@include media-breakpoint-down(lg) {
				transform: translate(-50%, -50%) scale(0.79) !important;
			}
		}

		&:nth-child(2n) {
			transform: translate(-5%, 50%);
			@include media-breakpoint-down(lg) {
				transform: translate(-5%, 10%);
			}
			z-index: 3;

			&::before {
				content: url("/images/splash-6.svg");
				transform: translate(-55%, -45%) scale(1.25);
				@include media-breakpoint-down(lg) {
					transform: translate(-10%, -10%) scale(1.25);
				}
			}
		}

		&:nth-child(3n) {
			transform: translate(0, 25%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			z-index: 4;
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			&::before {
				content: url("/images/splash-3.svg");
			}
		}

		&:nth-child(4n) {
			transform: translate(-10%, 50%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			z-index: 3;

			&::before {
				content: url("/images/splash-5.svg");
				transform: translate(-50%, -40%) scale(1.25);
				@include media-breakpoint-down(lg) {
					transform: none;
					content: url("/images/splash-4.svg");
				}
			}
		}

		&:nth-child(5n) {
			z-index: 2;
			transform: translate(0, 90%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			&::before {
				content: url("/images/splash-1.svg");
			}
		}

		&:nth-child(6n) {
			z-index: 1;
			transform: translate(-5%, 60%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			&::before {
				content: url("/images/splash-2.svg");
				transform: translate(-48%, -45%) scale(1.25);
				@include media-breakpoint-down(lg) {
					transform: none;
				}
			}
		}

		&:nth-child(7n) {
			z-index: 4;
			transform: translate(-10%, 10%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}

			&::before {
				content: url("/images/splash-4.svg");
				transform: translate(-50%, -40%) scale(1.25);
				@include media-breakpoint-down(lg) {
					transform: none;
					content: url("/images/splash-5.svg");
				}
			}
		}

		&:nth-child(8n) {
			z-index: 3;
			transform: translate(-5%, 50%);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
			&::before {
				content: url("/images/splash-7.svg");
				transform: translate(-53%, -50%) scale(1.25);
			}
		}
	}
}
