// footer
.footer {
	background-image: url("/images/paws.svg");
	background-position: bottom 50px right 50px;
	background-repeat: no-repeat;
	margin-top: 6vh;
	@include media-breakpoint-down(md) {
		background-position: bottom 15px right 15px;
		background-size: 40%;
	}
	.footer-intro {
		padding-top: 12vh;
		.logo {
			@include media-breakpoint-down(md) {
				max-width: 80%;
			}
		}
		.column {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			gap: 3vh;
			text-align: center;
			.footer-text.links {
				a {
					font-weight: bold;
					font-size: $h5-font-size;
					color: $black;
				}
			}
			div.vocabulary-term-list.social {
				margin-top: 2vh;

				ul {
					display: flex;
					li {
						position: relative;
						width: 5rem;
						aspect-ratio: 1/1;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: -10px;
						&::before {
							content: "";
							background: url("/images/splash-0.svg");
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center center;
							position: absolute;
							z-index: -1;
							width: 5rem;
							height: 5rem;
						}
						i {
							color: $white;
							font-size: 24px;
						}
						.list-item-title {
							display: none;
						}
						&:hover {
							scale: 1.1;
							transition: 0.35s ease;
							z-index: 2;
						}
						&:nth-child(2n) {
							transform: translate(0, 13%);
							@include media-breakpoint-down(md) {
								transform: translate(0, 25%);
							}
							&::before {
								background: url("/images/splash-2.svg");
								background-size: contain;
								background-repeat: no-repeat;
								background-position: center center;
							}
						}

						&:nth-child(3n) {
							transform: translate(0, -13%);
							@include media-breakpoint-down(md) {
								transform: translate(0, -25%);
							}
							&::before {
								background: url("/images/splash-3.svg");
								background-size: contain;
								background-repeat: no-repeat;
								background-position: center center;
								transform: translate(0%, -5%);
							}
						}
						&:nth-child(4n)::before {
							background: url("/images/splash-6.svg");
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center center;
							transform: translate(-5%, -5%);
						}
					}
				}
			}
			div.vocabulary-term-list.app {
				margin-top: 3vh;
				ul {
					display: flex;
					align-items: center;
					gap: 0.35rem;
					li {
						width: 150px;
						height: 45px;
						.list-item-title {
							display: none;
						}
						img {
							height: 100%;
						}
					}
				}
			}
		}
	}
	.footer-socket {
		margin-top: 6vh;
		padding: 0.5rem;
		.column {
			.list {
				justify-content: center;
				gap: 1rem;
				li {
					font-size: 14px;
					a {
						text-decoration: none;
						&:hover {
							text-decoration: underline;
							color: $green;
						}
					}
				}
				@include media-breakpoint-down(md) {
					flex-flow: column;
					justify-content: center;
					text-align: center;
					margin: 0 auto 12vh auto;
				}
			}
		}
	}
}
