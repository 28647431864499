// btn
.btn {
	border-radius: 35px;
	font-weight: 700;
	padding: 0.5rem 36px;
	position: relative;
	overflow: hidden;
	border: 2px solid;
	z-index: 2;

	&::before {
		content: "";
		height: 110%;
		width: 0%;
		background: transparent;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		transition: 0.35s ease;
	}
	&:hover {
		&::before {
			left: 0;
			width: 100%;
			transition: 0.35s ease;
		}
	}
	// btn-primary
	&.btn-primary {
		border-color: $primary;
		&::before {
			background: $secondary;
		}
		&:hover {
			border-color: $black;
			color: $black;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		border-color: $secondary;
		&::before {
			background: $primary;
		}

		&:hover {
			border-color: $primary;
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
