// 125% zoom fix
@media (-webkit-device-pixel-ratio: 1.25) {
	html {
		zoom: 0.8;
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: 0;
	background: $blue;
	border-radius: 15px;
	.card-body {
		padding: 1.5rem;
	}
	.card-caption {
		display: flex !important;
		flex-flow: column;
	}
	.card-title {
		color: $white;
		font-size: $h3-font-size;
	}
	.card-subtitle {
		color: $yellow-dark;
		font-family: $font-family-primary;
		font-weight: bold;
		font-size: $h5-font-size;
		order: -1;
		margin-bottom: 0;
	}
	.card-description {
		color: $white;
		ul {
			font-weight: 600;
		}
	}

	.card-btn {
		padding: 0;
		border: 0;
		color: $yellow-dark;
		border-radius: 0;
		&::before {
			display: none;
		}
		&:hover {
			text-decoration: 2px underline;
			text-underline-offset: 8px;
		}
	}
	@media (-webkit-device-pixel-ratio: 1.25) {
		.card-title {
			font-size: $h4-font-size;
		}
		.card-subtitle {
			font-size: $h5-font-size;
		}
	}
}

// images border
.gallery-item a {
	border-radius: 15px;
}

// border-animation

@keyframes borderAnimation {
	0% {
		border-bottom-color: #3fe9ff;
	}

	5% {
		border-bottom-color: #00e2ff;
	}

	10% {
		border-bottom-color: #3fb8ff;
	}

	15% {
		border-bottom-color: #00a0ff;
	}

	20% {
		border-bottom-color: #008cdf;
	}

	25% {
		border-bottom-color: #1c5d88;
	}

	30% {
		border-bottom-color: #204761;
	}

	35% {
		border-bottom-color: #ffdf00;
	}

	40% {
		border-bottom-color: #f8c823;
	}

	45% {
		border-bottom-color: #dfc400;
	}

	50% {
		border-bottom-color: #ccb300;
	}

	55% {
		border-bottom-color: #ffb53f;
	}

	60% {
		border-bottom-color: #ff9c00;
	}

	65% {
		border-bottom-color: #cc7c00;
	}

	70% {
		border-bottom-color: #c31162;
	}

	75% {
		border-bottom-color: #9c0e4d;
	}

	80% {
		border-bottom-color: #aa0e56;
	}

	85% {
		border-bottom-color: #ff3a44;
	}

	90% {
		border-bottom-color: #cc2d36;
	}

	95% {
		border-bottom-color: #df333b;
	}

	100% {
		border-bottom-color: #04ab38;
	}
}

// subtitle
.special-subtitle {
	color: $black;
	text-transform: uppercase;
	font-family: $font-family-primary !important;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.3rem;
	@include media-breakpoint-down(lg) {
		flex-flow: column;
		gap: 0.3rem;
		&::before,
		&::after {
			width: 100%;
			height: 1px !important;
		}
	}
	&::before,
	&::after {
		content: "";
		display: flex;
		height: 2px;
		min-width: 3px;
		background: $black;
		flex-grow: 1;
	}
}

// select
* {
	&::selection {
		background: $primary;
		color: $secondary;
	}
}

// mobile, desktop only

.mobile-only {
	display: none;
	@include media-breakpoint-down(lg) {
		display: block;
	}
}

.desktop-only {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
