// mini-sab
.mini-sab {
	z-index: 990;
	position: relative;
	margin-bottom: 12vh;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			background: $primary;
			border-radius: 15px;
			z-index: 900;
			position: relative;

			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}
			@include media-breakpoint-down(md) {
				margin-top: -6vh;
			}

			.column {
				padding-top: 1rem;
				padding-bottom: 2rem;
			}

			.heading {
				color: $white;
				font-family: $font-family-secondary;
				font-weight: 500;
				font-size: $h2-font-size;
				text-align: center;
				margin-bottom: 1rem;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
	.w3-popover-body {
		z-index: 999 !important;
		.checkbox-row .checkbox-item .state label {
			color: $black !important;
			width: 100% !important;
			display: flex !important;
			gap: 0.35rem !important;
			justify-content: flex-start !important;
			flex-flow: row !important;
			&::before {
				content: "";
				position: unset !important;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.container-holder {
			width: 90%;
		}
	}
}

&.w3-booking-widget-fullscreen-active {
	overflow: hidden;

    .mini-sab,
	&:not(.home) .main {
		position: relative;
		z-index: 2147483647;
	}
}