// header
.header {
	z-index: 998;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: $grey;
	transition: 0.5s;
	&.sticky {
		.three {
			position: fixed;
			top: 0;
			background: $body-bg;
			padding-top: 1.5rem;
			.logo {
				position: relative;
				width: 150px;
				transform: none;
				top: 0;
				@include media-breakpoint-up(xl) {
					img {
						left: 2.5rem;
					}
				}
			}
			border-bottom: 3px solid $body-bg;
			animation: borderAnimation ease;
			animation-timeline: scroll();
		}
	}
	&.sticky-mobile {
		@include media-breakpoint-down(md) {
			.three {
				position: fixed;
				top: 0;
				background: $body-bg;
				padding-top: 1.5rem;

				.logo {
					display: none;
				}

				border-bottom: 3px solid $body-bg;
				animation: borderAnimation ease;
				animation-timeline: scroll();
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-up(xl) {
					padding: 0 2.5rem;
				}

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.one {
			.column {
				padding: 0;
			}
			div.news-slider {
				background: $primary;
				height: 6vh;
				width: 100%;
				display: flex;
				align-items: center;
				padding-left: 1rem;

				ul {
					overflow: hidden;

					li {
						color: $white;
						display: flex;
						align-items: center;
						text-decoration: none;
						font-size: 15px;
						z-index: -1;
						position: absolute;
						left: 20px;
						top: 50%;
						opacity: 0;
						transform: translateY(-50%);
						@include media-breakpoint-up(xl) {
							padding: 0 2.5rem;
						}
						a {
							color: $white;
							display: flex;
							align-items: center;
							text-decoration: none;
							font-size: 15px;
						}
						i {
							font-size: 32px;
							max-height: 95%;
							margin-right: 0.55rem;
							@include media-breakpoint-down(md) {
								display: none;
							}
						}
						.list-item-title {
							font-weight: 600;
							margin: auto 0.5rem;
							@include media-breakpoint-down(md) {
								font-size: 10px;
								margin: auto 0.5rem auto 0;
								a[href^="tel"] {
									font-size: 10px;
									all: inherit;
								}
							}
						}
						.list-item-suffix {
							text-decoration: underline;
							@include media-breakpoint-down(md) {
								font-size: 10px;
								a[href^="tel"] {
									font-size: 10px;
									all: inherit;
								}
							}
						}
						&.active {
							z-index: 1;
							transition: 1s ease;
							opacity: 1;
						}
					}
				}
			}
		}
		&.two {
			padding-top: 1.5rem;
		}
		&.three {
			padding-bottom: 1.5em;
			@include media-breakpoint-down(md) {
				.column {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		justify-self: flex-start;
		margin-right: auto;
		.navbar-toggler {
			color: $blue-dark;
			i {
				transition: 0.35s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(360deg);
					transition: 0.35s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 302px;
		position: fixed;
		left: 20px;
		top: 6vh;
		transform: translateY(35%);

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-up(xl) {
			left: 2.5rem;
		}
		@include media-breakpoint-down(lg) {
			order: 1;
			flex-basis: 100%;
			position: relative;
			transform: none;
			top: 0;
			left: auto;
			max-width: 250px;
			margin: 1rem auto;
		}
	}

	// menu
	.menu:not(#secondary_menu) {
		@include media-breakpoint-down(lg) {
			order: 2;

			.navbar-nav {
				padding: 3rem 1rem;

				.nav-item {
					.nav-link {
						font-family: $font-family-secondary;
						color: $green-dark;
						font-size: 18px;
					}

					&.active {
						.nav-link {
							color: $primary !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
						}

						.nav-link {
							font-size: 16px;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			display: flex;
			justify-content: flex-end;
			margin-right: 1.5rem;

			.nav-item {
				margin-left: 1.15rem;
				.nav-link {
					font-size: 18px;
					font-weight: bold;
					color: $blue-dark;
				}
				&:hover,
				&.active {
					text-decoration: underline 2px;
					text-underline-offset: 8px;
					color: $mint-dark;
					.nav-link {
						color: $mint-dark;
					}
				}
				.dropdown-menu.show {
					border: 0;
					box-shadow: none;
					min-width: 15vw;
					&::before,
					&::after {
						display: none;
					}
					.nav-item {
						text-align: center;
						.nav-link {
							color: $black;
							&:hover {
								color: $mint-dark;
							}
						}
					}
				}
			}
		}
	}

	// secundair menu

	.menu#secondary_menu {
		@include media-breakpoint-up(xl) {
			width: 100%;
			display: flex;
			padding: 20px 0 20px 0;
			justify-content: flex-end;
			ul {
				display: flex;
				li a {
					display: flex;
					align-items: center;
					gap: 0.15rem;
					color: $black;
					font-size: 15px;

					&:hover {
						text-decoration: underline;
					}

					&.whatsapp-link {
						&::before {
							content: "\f232";
							font-family: "Font Awesome 5 Brands" !important;
							font-size: 18px;
							margin: 0;
							margin-right: 2px;
							line-height: 1.25;
							margin-bottom: 2px;
						}
					}
				}
			}
		}
	}
	div.book-button {
		a {
			@extend .btn, .btn-secondary;
			color: $blue-dark;
		}
		@include media-breakpoint-down(sm) {
			a {
				font-size: 14px !important;
				padding: 5px 10px !important;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 1rem;
		width: max-content;
		.navbar-nav {
			width: 100%;
			flex-flow: row nowrap !important;
			.nav-item {
				.nav-link {
					box-shadow: none !important;
					color: $black;
					font-size: 15px;
					padding: 8 0 !important;
				}
				&.active {
					font-weight: 600;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			@include media-breakpoint-down(lg) {
				gap: 0.35rem;
			}
		}
	}
}
