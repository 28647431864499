// eyecatcher

.eyecatcher .owl-carousel {
	.owl-item {
		@include media-breakpoint-up(lg) {
			margin-left: -20px;
			padding-right: 20px;
		}
	}
}

.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	border-radius: 30px;
	.owl-dots,
	.owl-nav,
	.owl-caption {
		display: none;
	}
	z-index: 700;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 70vh;
	min-height: 140px;
	max-height: 1080px;
	border-radius: 30px;
	filter: brightness(0.75);
	.owl-dots,
	.owl-nav,
	.owl-caption {
		display: none;
	}

	@include media-breakpoint-down(lg) {
		height: 40vh;
		border-radius: 0;
	}
}

section.eyecatcher {
	position: relative;
	z-index: 100;
	filter: none !important;
	.title {
		position: absolute;
		bottom: 9vh;
		left: 50%;
		transform: translateX(-50%);
		z-index: 995;
		width: 100%;
		text-align: center;

		* {
			font-size: 100px;
			color: $white !important;
			font-weight: 500;
			text-shadow: 4px 4px 10px rgba(#000, 0.5);
		}
		@include media-breakpoint-down(lg) {
			bottom: 8vh;
			* {
				font-size: 48px;
			}
		}
	}
	div.banner-highlight {
		position: absolute;
		top: 1.5rem;
		right: 2.5rem;
		z-index: 995;
		ul {
			li {
				.list-item-title {
					display: none;
				}
			}
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
